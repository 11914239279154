@import url('./animations.css');

.rse-container {
    margin-top: 15vh;
    text-align: center;
    margin-left: 5rem;
  }
  .title-second-rse {
    color: #008538;
    font-size: 3rem;
    margin-bottom: 1rem;
    animation: fadeInDown 1.5s ease;
  }
  
  .rse-content {
    max-width: 70vw;
    margin: 0 auto;
  }
  
  .rse-content p {
    color: rgb(84, 85, 84);
    font-size: 1.3rem;
    margin-bottom: 20px;
    animation: fadeFromLeft 1.5s ease;
  }
  
  .rse-image {
    width: 10rem; /* Ajusta el tamaño de la imagen según tus necesidades */
    margin-top: 1rem;
    transition: transform 0.3s ease;
    border-radius: 15%;
    cursor: pointer;
    animation: fadeInUp 1.5s ease;
  }
  .rse-image:hover{
    transform: scale(1.03);
    filter: brightness(1.01);
  }
  
  @media (max-width: 920px){
    .rse-content{
        max-width: 85vw;
    }
    .rse-container{
        margin-left: 0;
        margin-top: 15vh;
    }
    .rse-content p{
        font-size: 1rem;
    }
    .rse-image{
        max-width: 25vw;
        margin-bottom: 5rem;
    }
    .title-second-rse {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }
  }

  @media (max-width: 480px) {
    .rse-container{
        margin-left: 0;
        margin-top: 7.5vh;
    }
  }