/* src/styles/Programas.css */
.programas-container {
    margin-left: 5rem; /* Margen izquierdo para alinear con la navbar */
    padding: 2rem;
    text-align: center;
    margin-top: 10vh;
  }
  
  .title-programas {
    font-size: 2.55vw; /* Tamaño del texto del título */
    color: #008538; /* Color del texto del título */
    margin-bottom: 2.5rem; /* Espacio entre el título y las imágenes */
    animation: fadeFromLeft 1.1s ease-out; /* Animación para el título */
  }
  
  .programas-grid {
    display: flex;
    justify-content: center;
    gap: 1rem; /* Espacio entre las imágenes */
    flex-wrap: wrap; /* Las imágenes se envuelven en pantallas más pequeñas */
    animation: fadeInUp 1.1s ease;
  }
  
  .programa-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none;
    width: 18vw;
    max-width: 300px; /* Tamaño máximo de las imágenes */
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, filter 1s ease;
  }
  
  .programa-item:hover {
    filter: brightness(1.25); /* Efecto de brillo al pasar el cursor */
    transform: scale(1.05); /* Efecto de zoom al pasar el cursor */
  }
  
  .programa-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  @media (max-width: 1200px) {
    .title-programas{
      font-size: 3.3vw;
    }
    .programa-item {
      width: 21vw;
    }
  }
  
  @media (max-width: 920px) {

    .programas-container {
      margin-top: 20vh;
      margin-left: 0;
    }
    .programa-item {
      width: 25vw;
    }
  }
  
  @media (max-width: 700px) {
    .programas-container{
      margin-top: 1.5vh;
      margin-bottom: 4rem;
    }
    .programa-item {
      width: 35vw;
    }
    .title-programas {
      font-size: 4.5vw;
      margin-bottom: 2rem;
    }
  }
  
  @media (max-width: 480px) {
    .programa-item {
      width: 38.5vw;
    }
    .title-programas {
      font-size: 1.45rem;
      margin-top: 4vw;
    }
  }
  