.dvtic-container {
    margin-left: 5rem; /* Espacio para la navbar */
    padding: 1rem; /* Espacio interno para que el contenido no toque los bordes */
    text-align: center;
    color: #333; /* Color de texto oscuro para buena legibilidad */
    margin-top: 3vh;
    margin-bottom: 3rem;
    animation: fadeInUp 1s ease;
  }
  .dvtic-content {
    max-width: 70vw; /* Ancho máximo para el contenido */
    margin: 0 auto; /* Centra el contenido horizontalmente */
  }
  
  .dvtic-container .dvtic-title{
    margin-top: 3vh;
    color: #008538;
    border: none;
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  .dvtic-container p {
    border-top: 1px solid black;
    padding: 1.5rem;
    font-size: 1.45rem; /* Tamaño de fuente para la descripción */
    line-height: 1.6; /* Altura de línea para mejorar la legibilidad */
    margin-bottom: 1.75rem; /* Espacio debajo de la descripción */
    color: rgb(84, 85, 84);

  }
  
  @media (max-width: 1280px) {
    .dvtic-container {
      margin-top: 0;
      margin-left: 8rem;
      margin-right: 3rem;
    }
    .dvtic-content {
        max-width: 80vw; /* Ancho máximo para el contenido */
      }
  }
  @media (max-width: 920px) {
    .dvtic-container {
      margin-left: 2rem;
      margin-right: 2rem;
      margin-bottom: 4rem;
      margin-top: -2.5rem;
    }
    .dvtic-content {
        max-width: 100vw; /* Ancho máximo para el contenido */
        margin: 0 auto; /* Centra el contenido horizontalmente */
      }
  }
  
@media (max-width: 480px) {
.dvtic-content {
    max-width: 100vw;
    padding: 0;
    margin: 0;
    /* Ancho máximo para el contenido */
}

.dvtic-container .dvtic-title{
    font-size: 2.2rem;
}
.dvtic-container p {
    font-size: 1.35rem; /* Tamaño de fuente para la descripción */
  }
}