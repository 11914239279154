
.modalidades-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 12vh;
    margin-left: 5rem;
    text-align: center;
  }
  
  .modalidades-text {
    flex: 1;
    padding: 1rem;
    margin-left: 6vw;
    animation: fadeOff 1.2s ease;
  }
  
  .modalidades-text h1 {
    border-bottom: 1px solid rgb(47, 48, 47);
    font-size: 3.3vw;
    color: #008538;
    margin-bottom: 1rem;
  }
  
  .modalidades-info {
    display: flex;
    flex-direction: column;
  }
  
  .modalidades-item {
    margin-bottom: 2.5vh;
  }
  
  .modalidades-item h2 {
    font-weight: bold;
    font-size: 2vw;
    margin-bottom: 0.3rem;
    color: rgb(98, 99, 98);
  }
  
  .modalidades-item p {
    color: rgb(84, 85, 84);
    font-size: 1.3vw;
  }
  
  .modalidades-video {
    flex: 1;
    padding: 1rem;
    animation: fadeInUp 1s ease;
  }
  
  .modalidades-video video {
    width: 28vw;
    height: auto;
    border: 10px solid #008538;
  }
  
  @media (max-width: 1200px) {
    .modalidades-text h1 {
        font-size: 3rem;
      }
    .modalidades-item h2 {
        font-size: 1.6rem;
      }
      .modalidades-item p {
        font-size: 0.9rem;
      }
    .modalidades-container {
        margin-top: 18vh;
      }
      .modalidades-video video {
        width: 19rem;
        height: auto;
        border: 10px solid rgb(87, 182, 87);
      }
  }

  @media (max-width: 920px) {
    .modalidades-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 20vh;
        margin-left: 0;
        text-align: center;
      }
  }

  @media (max-width: 768px) {
    .modalidades-container {
        margin-top: 0;
      }
    .modalidades-video{
        flex: 0;
    }
    .modalidades-container {
      flex-direction: column;
      margin-left: 3rem;
      margin-right: 3rem;
      margin-top: 2rem;
    }
    .modalidades-text {
        flex: 1;
        padding: 1rem;
        margin-top: 0;
        margin-right: 0;
        
        margin: 0;
      }
    .modalidades-video video {
        margin-top: 0;
        margin: 0;
        padding: 0;
        width: 19rem;
        height: auto;
        border: 10px solid rgb(87, 182, 87);
    }
  }
  
  @media (max-width: 700px) {
    .modalidades-container {
        margin-top: 1rem;
    }
    .modalidades-video video {
        width: 17rem;
    }
    .modalidades-item h2 {
        font-size: 1.65rem;
    }
    .modalidades-item p {
        font-size: 0.95rem;
    }
  }


  @media (max-width: 480px) {

    .modalidades-text h1 {
        font-size: 2.25rem;
      }
    .modalidades-item h2 {
        font-size: 1.35rem;
      }
      .modalidades-item p {
        font-size: 0.9rem;
      }
    .modalidades-container {
        margin-top: 0;
      }
      .modalidades-video video {
        width: 16rem;
        margin-bottom: 4rem;
      }
  }