@import url('./animations.css');

/* Contenedor principal */
.bSection {
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    margin-top: 1vh;
    position: relative;
    top: 0;
    left: 0;
    animation: fadeOff 0.7s ease-in-out;
    transition: 1s;
    overflow: hidden;
}

.bRigth {
    margin-right: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* Parte izquierda con texto */
.bLeft {
    width: 50%;
    color: rgb(63, 63, 63);
}

/* Título */
.bTittle {
    margin-top: 8vh;
    font-size: 2.5vw;
    transition: filter 0.3s;
    color: rgb(98, 99, 98);
}

.bTittle:hover {
    filter: brightness(1.2);
}

/* Texto */
.bText {
    color: rgb(84, 85, 84);
    font-size: 1.5vw;
    margin: 10px 6%;
}

/* Video */
.bVideo {
    width: 26vw;
    height: auto;
    max-height: 90vh;
    object-fit: cover;
}

/* Estilos para el icono de WhatsApp */
.whatsapp-icon {
    position: fixed;
    bottom: 1.3rem;
    right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000; 
}

.whatsapp-icon img {
    margin-bottom: 1rem;
    width: 4.3rem;
    opacity: 0.4;
    transition: transform 0.3s ease, opacity 0.8s ease;
}

.whatsapp-icon img:hover {
    transform: scale(1.1);
    opacity: 1;
}

/* Estilos para el otro icono */
.campus-icon {
    position: fixed;
    bottom: 20px;
    left: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none; /* Para eliminar el subrayado del enlace */
    z-index: 1000; /* Asegura que el icono esté sobre todo el contenido */
}

.campus-icon img {
    margin-bottom: 1rem;
    width: 12rem;
    transition: transform 0.3s ease, opacity 0.8s ease;
    opacity: 0.4;
}

.campus-icon img:hover {
    transform: scale(1.1);
    opacity: 1;
}

/* Media queries para dispositivos móviles */
@media (max-width: 1400px) {
    .bSection {
        cursor: default;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: white;
        margin-top: 3vh;
        position: relative;
        top: 0;
        left: 0;
        animation: fadeOff 0.7s ease-in-out;
        transition: 1s;
        overflow: hidden;
    }

    
    /* Título */
    .bTittle {
        margin-top: 5vh;
        font-size: 2.7vw;
        transition: filter 0.3s;
        color: rgb(98, 99, 98);
    }
    /* Texto */
    .bText {
        color: rgb(84, 85, 84);
        font-size: 1.35vw;
        margin: 10px 6%;
    }
    
    /* Video */
    .bVideo {
        width: 25vw;
        height: auto;
        max-height: 90vh;
        object-fit: cover;
    }
    .bLeft {
        width: 60%;
    }

}

@media (max-width: 1300px) {
    .bSection{
        margin-top: 5vh;
    }
}
/* Estilos para el contenedor de botones de imágenes */
.image-buttons-container {
    position: fixed; /* Mantiene el contenedor en la parte inferior */
    border-top: 1px solid  #008538;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 1rem; /* Espacio entre las imágenes */
    background: white; /* Fondo blanco para visibilidad */
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.1); /* Sombra sutil para separación */
}

.image-button {
    width: 8.5rem; /* Tamaño inicial de las imágenes */
    height: auto;
    margin-left: 0.5rem;
    gap: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.8s ease;
    border-radius: 10px;
    border-right: 5px solid  #008538;
    border-bottom: 5px solid  #008538;
}

.image-button:hover {
    transform: scale(1.1);
}

@media (max-width: 1445px) {
    .whatsapp-icon img{
        display: none;
    }
    .campus-icon img{
        display: none;
    }
    .image-buttons-container{
        justify-content: center;
    }
    .container-int{
        margin-right: 0vh;
    }
    .bRigth {
        margin-right: 0rem;
    }
    .bSection {
        flex-direction: column-reverse;
        margin-top: 8vh;
        margin-bottom: 7rem;
    }
    .bTittle {
        font-size: 40px;
    }
    .bText {
        margin: 0 20px;
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 2rem;
    }
    .bLeft {
        width: 80%;
    }
    .bVideo {
        margin: 0 auto;
        width: 60%;
        height: auto;
    }
    .whatsapp-icon img{
        width: 5rem; /* Tamaño inicial ajustable */
    }
    .campus-icon img{
        width: 13rem;
    }
}

@media (max-width: 600px) {
    .whatsapp-icon img{
        width: 5rem; /* Tamaño inicial ajustable */
    }
    .campus-icon img{
        width: 13rem;
    }
}

@media(max-width: 480px) {
    .whatsapp-icon img {
        width: 3.5rem;
        opacity: 1;
    }
    .campus-icon img{
        width: 10rem;
        opacity: 1;
    }
    .bSection {
        margin-top: 6.5vh;
    }
    .bVideo {
        width: 70%;
    }
}

/* Estilos para el botón del modal */
.view-images-button {
    margin-top: 0.3rem;
    padding-top: 1rem;
    padding: 10px 20px 7px 20px;
    margin-bottom: 5rem;
    font-size: 1.3rem;
    cursor: pointer;
    background-color:  #9a9a9a;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
}

.view-images-button:hover {
    transform: scale(1.05);
    background-color: #008538;
}



@media (max-width: 1103px) {
    .image-buttons-container {
        position: fixed; /* Mantiene el contenedor en la parte inferior */
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        background: white; /* Fondo blanco para visibilidad */
        padding: 10px;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil para separación */
        overflow-x: auto; /* Permite desplazamiento horizontal si las imágenes no caben */
        flex-wrap: nowrap; /* Asegura que las imágenes no se apilen */
    }

    .image-button {
        width: 6.5rem; /* Tamaño inicial de las imágenes */
        height: auto;
        cursor: pointer;
        transition: transform 0.3s ease, opacity 0.8s ease;
    }

    .whatsapp-icon img {
        display: none;
    }

    .campus-icon img {
        display: none;
    }
}

@media (max-width: 820px) {
    .image-button {
        margin-left: 10px;
        border-radius: 0.7rem;
        border-right: 2px solid green;
        border-bottom: 2px solid green;
    }
    .image-button{
        width: 11vw;
    }
}
@media (max-width: 480px) {
    .image-button {
        width: 7.2vh;
    }
    .view-images-button {
        margin-bottom: 0rem;
    }
    .bLeft {
        width: 100%;
        color: rgb(63, 63, 63);
    }
    .bVideo {
        width: 85vw;
        height: auto;
        max-height: 90vh;
        object-fit: cover;
    }
}


/* Estilos para la flecha de expansión */

.expand-arrow {
    display: none;
    position: fixed;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 1001;
    background-color: none;
    border-radius: 10px;
    align-items: center;
}

.expand-arrow img {
    width: 2.2rem;
    animation: saltitos 1.5s ease infinite;
}
/* Ajustar la posición cuando está expandido */
.expand-arrow.expanded {
    left: 80%; /* Mueve la flecha a la derecha */
    bottom: 2rem; /* Ajusta la posición vertical si es necesario */
    transform: translateX(0); /* Quita el centrado */
}

  /* Mostrar la flecha solo en pantallas pequeñas */
  @media (max-width: 600px) {
    .expand-arrow {
      display: block;
    }
    /* Estilos cuando los botones están expandidos */
    .image-buttons-container.container-int.expanded {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background: rgba(255, 255, 255, 0.95); /* Fondo blanco semitransparente */
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 10px;
      z-index: 1000; /* Asegura que los botones estén sobre todo */
      animation: fadeInUp 0.4s ease;
    }
  
    .image-button.expanded {
      border-radius: 20px;
      width: 11rem;
      height: auto;
      margin: 5px;
    }
  }

  @media (max-width: 460px) {
    .expand-arrow img {
        width: 2rem;
    }

    .expand-arrow {
        bottom: 3.3rem;
    }

    .expand-arrow.expanded {
        left: 85%;
    }
}