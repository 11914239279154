@import url('./animations.css');

.img-grid {
  display: flex;
  justify-content: center; /* Centra las imágenes en la pantalla */
  flex-wrap: wrap; /* Permite que las imágenes se envuelvan en la siguiente línea si no caben en una fila */
  gap: 0.8rem; /* Espacio entre las imágenes */
  margin-top: 25vh; /* Ajusta el margen superior para separar las imágenes del título */
  transition: 0.3s;
}

.cloud360-container {
  margin-left: 5rem; /* Ajusta este margen según el ancho de tu navbar */
  margin-bottom: 0;
}
.im1 {
  width: 13vw; /* Ancho de las imágenes */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.2s ease; /* Transición suave para el efecto hover */
  border-radius: 25px;
  border-bottom: 10px solid #008538;
  border-right: 10px solid #008538;
  animation: fadeInUp 1.3s ease-out;
}

.im1:hover {
  transform: scale(1.05); /* Efecto de agrandamiento al hacer hover */
  box-shadow: 0px 2px 18px rgb(147, 212, 147);
}
@media (max-width: 1200px) {
  .im1 {
    width: 16vw; /* Ajusta el ancho de las imágenes en pantallas más pequeñas */
  }
  .img-grid {
    margin-top: 22vh;
  }
}

@media (max-width: 920px) {
  .cloud360-container {
    margin-left: 0;
    margin-bottom: 4rem;
  }
  .im1 {
    width: 20vw;
  }
}

@media (max-width: 700px) {
  .im1 {
    width: 30vw;
  }
  .img-grid {
    margin-top: 14vh;
  }
}

@media (max-width: 480px) {
  .cloud360-container {
    margin-left: 0; 
    margin-bottom: 4rem;
  }
  .im1 {
    width: 40vw;
  }
  .img-grid {
    margin-top: 5vh;
    margin-bottom: 8rem;
  }
}
