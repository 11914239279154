.deportes-container {
    margin-left: 5rem; /* Espacio para la navbar */
    padding: 1rem; /* Espacio interno para que el contenido no toque los bordes */
    text-align: center;
    color: #333; /* Color de texto oscuro para buena legibilidad */
    margin-top: 7vh;
    animation: fadeInUp 1s;
  }
  
  .deportes-content {
    max-width: 75vw; /* Ancho máximo para el contenido */
    margin: 0 auto; /* Centra el contenido horizontalmente */
  }
  
  .deportes-title {
    font-size: 2rem; /* Tamaño de fuente grande para el título */
    margin-bottom: 1.5rem; /* Espacio debajo del título */
    color: rgb(98, 99, 98); /* Color del título para mayor contraste */
  }
  
  .deportes-description {
    font-size: 1.1rem; /* Tamaño de fuente para la descripción */
    line-height: 1.6; /* Altura de línea para mejorar la legibilidad */
    margin-bottom: 2rem; /* Espacio debajo de la descripción */
    color: rgb(84, 85, 84);
  }
  
  .deportes-benefits {
    display: flex;
    flex-direction: column; /* Columnas en pantallas pequeñas */
    gap: 1.5rem; /* Espacio entre las secciones de beneficios */
  }
  
  .benefits-users,
  .benefits-admins {
    background-color: #008538; /* Fondo verde claro para destacar */
    padding: 1.5rem; /* Espacio interno */
    border-radius: 10px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra suave para efecto de profundidad */
    font-size: 1rem;
    color: white;
  }
  
  .benefits-users h2,
  .benefits-admins h2 {
    font-size: 1.3rem; /* Tamaño de fuente para los subtítulos */
    font-weight: bold;
    color: white; /* Color verde oscuro para los subtítulos */
    margin-bottom: 0.5rem; /* Espacio debajo de los subtítulos */
    
  }
  
  .deportes-summary {
    margin-top: 2rem; /* Espacio encima del resumen */
  }
  
  .deportes-summary h2 {
    font-size: 1.5rem; /* Tamaño de fuente para el resumen */
    margin-bottom: 1rem; /* Espacio debajo del subtítulo del resumen */
    color: rgb(98, 99, 98); /* Color del resumen para mayor contraste */
  }
  
  .deportes-summary p {
    font-size: 1.1rem; /* Tamaño de fuente para el párrafo del resumen */
    line-height: 1.6; /* Altura de línea para el párrafo */
    color: rgb(84, 85, 84);
}
  
  @media (max-width: 1100px) {
    .deportes-container {
      margin-top: 1rem;
    }
  }
  @media (max-width: 920px) {
    .deportes-container {
      margin-left: 0;
      margin-bottom: 4rem;
      margin-top: 1rem;
    }
    .deportes-content {
        max-width: 100vw; /* Ancho máximo para el contenido */
        margin: 0 auto; /* Centra el contenido horizontalmente */
      }
  }
  
  @media (max-width: 480px) {
    .deportes-container {
        margin-top: -0.5rem;
      }
    .deportes-title {
        font-size: 1.3rem; /* Tamaño de fuente grande para el título */
        margin-bottom: 1rem; /* Espacio debajo del título */
      }
      
      .deportes-description {
        font-size: 0.9rem; /* Tamaño de fuente para la descripción */
        line-height: 1.4; /* Altura de línea para mejorar la legibilidad */
        margin-bottom: 1rem; /* Espacio debajo de la descripción */
      }
      
      .deportes-benefits {
        gap: 1rem; /* Espacio entre las secciones de beneficios */
      }
      
      .benefits-users,
      .benefits-admins {
        padding: 1rem; /* Espacio interno */
        border-radius: 30px; /* Bordes redondeados */
        font-size: 0.8rem;
      }
      
      .benefits-users h2,
      .benefits-admins h2 {
        font-size: 1.3rem; /* Tamaño de fuente para los subtítulos */
        margin-bottom: 0.3rem; /* Espacio debajo de los subtítulos */
      }
      
      .deportes-summary {
        margin-top: 1rem; /* Espacio encima del resumen */
      }
      
      .deportes-summary h2 {
        font-size: 1.3rem; /* Tamaño de fuente para el resumen */
        margin-bottom: 0.2rem; /* Espacio debajo del subtítulo del resumen */
      }
      
      .deportes-summary p {
        font-size: 0.8rem; /* Tamaño de fuente para el párrafo del resumen */
        line-height: 1.4; /* Altura de línea para el párrafo */
      }
  }