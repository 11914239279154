/* General Styles */
.uSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  min-height: 100vh;
  z-index: 1;
  transition: 2s;
  position: relative; /* Cambiado de fixed a relative */
  overflow-y: hidden; /* Permitir scroll vertical si el contenido es demasiado grande */
  animation: fadeOff 0.8s ease-in-out;
  margin-top: -10vh
}
.sMedia {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fRight {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Añadido para mejorar la responsividad */
}

.fContacto {
  margin: 2rem 1rem; /* Cambié a rem */
  display: flex;
  flex-direction: row;
  cursor: default;
}

.fCIcon {
  width: 5rem; /* Cambié a rem */
}

.fData {
  margin: auto 0;
}

.fCTitle {
  color: rgba(0, 128, 0, 0.761);
  font-size: 1.375rem; /* Cambié a rem */
  margin: 0.3rem 0.6rem; /* Cambié a rem */
}

.fCText {
  color: rgb(75, 75, 75);
  margin: 0.3rem 0.6rem; /* Cambié a rem */
  font-size: 1.25rem; /* Cambié a rem */
}

.fLogo {
  margin-top: 5vh; /* Cambié a vh */
  width: 20rem; /* Cambié a vw */
  height: auto;
}

.fSocial {
  width: 2.7rem; /* Cambié a vw */
  margin: 0 10px; /* Reducido para acercar los iconos */
  cursor: pointer;
  transition: 0.5s;
  border-radius: 0.4em;
}

.fSocial:hover {
  filter: brightness(2);
}

/* Responsive Styles */
@media (max-width: 906px) {
  .uSection{
    margin-top: -5vh;
  }
  .fRight {
    flex-direction: column;
  }
  .fCTitle {
    font-size: 1.7rem; /* Cambié a rem */
  }

  .fCText {
    font-size: 1.1rem; /* Cambié a rem */
  }

  .fLogo {
    margin-top: 5vh; /* Cambié a vh */
    width: 14rem; /* Cambié a rem */
  }

  .fSocial {
    width: 2rem; /* Cambié a rem */
  }
}
