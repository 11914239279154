@import url('./animations.css');
.desc-spon-container{
  margin-top: 20vh;
  padding: 1.5rem;
  line-height: 2rem;
  text-align: center;
}
.description-spon-container{
  text-align: center;
  margin-bottom: 4rem;
}
.sponsors-description{
  color: rgb(92, 94, 92);
  font-size: 1.7vw;
  border-radius: 20px;
  padding: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
  animation: fadeOff 2.2s ease-in-out;
}
.img-grid-spon {
  display: flex;
  flex-wrap: wrap; /* Permite que las imágenes se envuelvan en la siguiente línea si no caben en una fila */
  justify-content: center; /* Centra las imágenes en la pantalla */
  gap: 5vw; /* Espacio entre las imágenes */
  margin-top:3vh; /* Ajusta el margen superior para separar las imágenes del título */
  transition: 0.3s;
  margin-left: 1rem;
  margin-right: 1rem;
}

.title-second-spon {
  font-size: 3rem;
  text-align: center;
  color: #008538;
  margin-top: 17vh;
  margin-bottom: 0.5vw;
  animation: fadeFromLeft 1.3s ease-out; /* Aplica la animación */
}

.spon-container {
  margin-left: 5rem; /* Ajusta este margen según el ancho de tu navbar */
  margin-bottom: 0;
  transition: 1s;
}

.img-spon {
  width: 8vw; /* Ancho de las imágenes reducido */
  cursor: pointer;
  transition: transform 0.4s; /* Transición suave para el efecto hover */
  transition: filter 1s;
  animation: fadeInUp 1.3s ease;
}

.spon:hover {
  transition: 0.3s;
  transform: scale(1.065); /* Efecto de agrandamiento al hacer hover */
  filter: brightness(1.05);
}

@media (max-width: 1200px){
  .img-spon{
    margin-top: 2rem;
  }
  .img-grid-spon{
    gap: 3.5rem;
  }

  .description-spon-container{
    margin-bottom: 0;
  }
}

@media (max-width: 920px) {
  .img-grid-spon {
    gap: 6.5vw;
    
  }
  .title-second-spon {
    margin-top: 17vh;
  }
  .img-spon {
    width: 6rem;
  }

  .spon-container {
    margin-left: 0;
  }
  .description-spon-container{
    margin-bottom: 2rem;
  }
  .sponsors-description{
    font-size: 1.2rem;
  }
}

@media (max-width: 700px) {
  .title-second-spon {
    margin-top: 14vh;
    font-size: 2.2rem;
  }
  .img-spon {
    width: 12vw;
  }
  .spon-container {
    margin-left: 0;
    margin-bottom: 1rem;
  }
  .img-grid-spon {
    gap: 8vw; /* Espacio entre las imágenes */
    margin-top: 0; /* Ajusta el margen superior para separar las imágenes del título */
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .sponsors-description{
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .title-second-spon {
    margin-top: 3rem;
    font-size: 1.7rem;
  }
  .spon-container {
    margin-left: 0;
    margin-bottom: 4rem;
  }
  .img-spon {
    border-radius: 10px;
    width: 5rem;
    padding: 10px 10px;
  }
  .img-spon {
    cursor: pointer;
    border-radius: 10%;
    transition: transform 0.4s; /* Transición suave para el efecto hover */
    transition: filter 1s;
    margin-left: 0;
    margin-right: 0;
  }
  .img-grid-spon {
    margin-top: 1vh;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center; /* Centra los elementos en la columna */
  }
}