/* src/styles/Nosotros.css */
@import url('./animations.css');

.nosotros-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  background-color: white; /* Ajusta el color de fondo si es necesario */
  padding: 2rem;
}

.nosotros-section {
  margin-top: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem; /* Espacio entre la imagen y el texto */
  margin-bottom: 1rem;
  animation: fadeFromLeft 2s ease;
}

.nosotros-heading {
  color: #5cb615;
  font-size: 4rem; /* Tamaño más grande para mayor impacto */
  font-weight: bold;
  flex-shrink: 0; /* Evita que el texto se reduzca en tamaño */
}

.nosotros-text {
  
  font-weight: bold;
  border-top: 2px solid rgb(75, 189, 75);
  padding: 1rem;
  color: rgb(84, 85, 84);
  font-size: 1.5rem; /* Ajusta según lo que consideres adecuado */
  line-height: 1.6;
  max-width: 800px; /* Limita el ancho del texto para mejor legibilidad */
  margin: 0 auto;
  animation: fadeFromRight 2s ease;
}

.nosotros-image {
  width: 100%;
  max-width: 20rem; /* Tamaño más grande si se desea destacar la imagen */
  margin: 1rem;
  animation: fadeFromLeft 1s ease;
}

/* Media queries para dispositivos móviles */
@media (max-width: 1200px) {
  .nosotros-heading {
    font-size: 3.5rem;
  }
  .nosotros-text {
    font-size: 1.2rem;
  }
  .nosotros-image {
    max-width: 16rem;
  }
}

@media (max-width: 672px) {
  .nosotros-heading {
    font-size: 3rem;
  }
  .nosotros-text {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .nosotros-heading {
    font-size: 2.5rem;
  }
  .nosotros-text {
    font-size: 1rem;
    padding: 0 1rem; /* Añade padding para evitar que el texto se adhiera a los bordes */
  }
  .nosotros-image {
    max-width: 12rem;
  }
  .nosotros-container {
    margin-top: -2vh;
  }
}
