/* src/styles/Navbar.css */
@import url('./animations.css');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'suave', Arial, sans-serif;
}

.navbar {
  position: relative;
  z-index: 1000; /* Un valor alto para estar por encima de otros elementos */
  background-color:  #008538;
  border-radius: 10px;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  box-shadow: 0 4px 8px rgb(98, 112, 99);
  animation: fadeInDown 1.3s;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 4rem;
  width: auto;
  transition: 0.4s;
  margin-top: 1rem;
  margin-left: 30px;
  margin-bottom: 0.5rem;
}

.logo:hover {
  transform: scale(1.1);
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
}

.nav-links li {
  margin-right: 4vw;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  transition: 0.3s;
  
}
.nav-links a:hover{
  transform: scale(1.12);
}

.nav-links .span-cloud {
  font-size: 0.95em;
  color:  white;
  transition: 0.3s;

}


.nav-links .nub-icon {
  width: 4rem; /* Ajusta el tamaño del ícono */
  height: auto;
  margin-left: 1rem;
  transition: 0.3s;
  margin-bottom: 1rem;
}

.nav-links.active a img:hover {
  
  transform: scale(1.55);
  margin: 0 100px;
}

.nav-links.active a span {
  font-size: 1.8em;
  
}

.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 2;
  margin-right: 2em;
  z-index: 1001;
}

.menu-toggle .bar {
  width: 3rem;
  height: 0.32rem;
  background-color: rgb(9, 95, 9);
  margin: 6px 0;
  transition: 0.2s;
}


@media (max-width:600px){
  .nav-links .nub-icon {
    margin-left: 0.45rem;
  }
}
@media (max-width: 950px) {
  .nav-links a {
    text-decoration: none;
    font-size: 3vw;
    display: flex;
    align-items: center;
  }
  
  .nav-links li {
    margin-right: 4.5vw;
  }

  .logo {
    height: 7vw;
    margin-left: 1rem;
  }
  .nav-links .nub-icon {
    display: none;
  }
}


@media (max-width: 500px) {
  .nav-links .nub-icon {
    display: block;
  }
  .navbar {
    
    margin-left: 1rem;
    margin-right: 1rem;   
  }

  .nav-links {
    
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -120%;
    width: 100%;
    height: 70vh;
    background-color: white;
    justify-content: center;
    align-items: center;
    border: 1.2px solid black;
    z-index: 1000;
  }

  .nav-links a {
    color: black;
    font-size: 1.2rem;
    border-bottom: 1.5px solid black;
  }

  .logo {
    height: 3rem;
    width: auto;
    transition: 0.4s;
    margin-left: 1.5rem;
  }

  .nav-links.active {
    left: 0;
  }

  .nav-links li {
    margin-top: 5rem;
  }

  .menu-toggle {
    display: flex;
    margin: 15px;
    margin-right: 20px;
  }

  .menu-toggle.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-10px, 10px);
  }

  .menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-13px, -14px);
  }

  .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }

  .overlay.active {
    display: block;
  }
}
.nav-links a.active {
  transform: scale(0.9);
  color: black;
  cursor: default;
}
