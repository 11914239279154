@import url('./animations.css');

.img-grid-edu {
  display: flex;
  justify-content: center; /* Centra las imágenes en la pantalla */
  flex-wrap: wrap; /* Permite que las imágenes se envuelvan en la siguiente línea si no caben en una fila */
  gap: 1.15rem; /* Espacio entre las imágenes */
  margin-top: 25vh; /* Ajusta el margen superior para separar las imágenes del título */
  transition: 0.3s;
}

.edu-container {
  margin-left: 5rem; /* Ajusta este margen según el ancho de tu navbar */
  margin-bottom: 0;
}
.img-edu {
  width: 15vw; /* Ancho de las imágenes */
  cursor: pointer;
  transition: transform 0.4s; /* Transición suave para el efecto hover */
  border-radius: 35px;
  border-bottom: 10px solid #008538;
  border-right: 10px solid #008538;
  animation: fadeInUp 1.3s ease-out;
}

.img-edu:hover {
  transform: scale(1.065); /* Efecto de agrandamiento al hacer hover */
  box-shadow: 0px 2px 18px rgb(147, 212, 147);
}

@media (max-width: 1250px) {
  .img-edu {
    width: 14rem; /* Ajusta el ancho de las imágenes en pantallas más pequeñas */
  }
}

@media (max-width: 920px) {
  .img-edu {
    width: 30vw;
  }
  .img-grid-edu {
    margin-top: 23vh;
  }
  .edu-container{
    margin-left: 0;
  }
}

@media (max-width: 700px) {
  .img-edu {
    width: 14rem;
  }
  .img-grid-edu {
    margin-top: 9vh;
  }
  .edu-container {
    margin-left: 0; 
    margin-bottom: 5rem;
  }
}

@media (max-width: 480px) {
  .edu-container {
    margin-left: 0; 
    margin-bottom: 4rem;
  }
  .img-edu {
    border-radius: 20px;
    width: 40vw;
  }
  .img-grid-edu {
    margin-top: 10vh;
    margin-bottom: 5rem;
  }
}
