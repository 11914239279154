@import url('./animations.css');

/* Estilos para el overlay del modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(55, 134, 52, 0.322); /* Fondo semitransparente para el overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
    backdrop-filter: blur(12px);
}

/* Estilos para el contenido del modal */
.modal-content {
    background: white;
    padding: 1rem;
    position: relative;
    width: 50vw; /* Ancho fijo del modal */
    height: 80vh; /* Altura fija del modal */
    overflow: hidden; /* Oculta el desbordamiento */
    box-shadow: 0 4px 8px #8ebf2f; /* Añade sombra para mayor contraste */
    animation: fadeOff 3s ease; /* Añade animación al abrir el modal */
}

/* Estilos para el botón de cerrar */
.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333; /* Color del botón de cerrar */
}

/* Contenedor de las imágenes y botones de navegación */
.modal-body {
    display: flex;
    flex-direction: column;
    height: 100%; /* Usar toda la altura del modal */
}

/* Contenedor de las vistas previas */
.modal-preview {
    display: flex;
    overflow-x: auto;
    padding: 1.5rem 0;
    border-bottom: 1px solid #ddd;
    max-height: 20%; /* Limitar la altura del contenedor de previsualización */
}

/* Estilos para las vistas previas de las imágenes */
.modal-thumbnail {
    width: 80px;
    height: 60px;
    object-fit: cover;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.3s ease, transform 0.2s ease;
}
.modal-thumbnail:hover {
    transform: scale(1.05);
}
.modal-thumbnail.active {
    border-color: #8ebf2f; /* Color del borde activo */
}

/* Estilos para las vistas previas de los videos */
.video-thumbnail {
    width: 80px;
    height: 60px;
    background-color: #000; /* Fondo negro para el área del video */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff; /* Color del texto */
    font-size: 24px; /* Tamaño del ícono */
    cursor: pointer;
}

/* Contenedor de la imagen principal y los botones de navegación */
.modal-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    position: relative;
    overflow: hidden; /* Ocultar desbordamiento */
}

/* Contenedor para ajustar la imagen principal */
.modal-image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Estilos para la imagen principal dentro del modal */
.modal-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Mantiene la relación de aspecto de las imágenes */
}

/* Estilos para los videos principales dentro del modal */
.modal-media {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ajusta el video manteniendo su relación de aspecto */
    display: block; /* Asegura que el video ocupe su espacio correctamente */
}

/* Estilos para los botones de navegación */
.modal-nav-button {
    background: #8ebf2f;
    border: 0.5px solid green;
    font-size: 1.2rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 1rem;
    border-radius: 15px;
    z-index: 10; /* Asegura que los botones estén por encima de la imagen */
}

/* Botón de navegación izquierda */
.modal-nav-button:first-of-type {
    left: 10px;
}

/* Botón de navegación derecha */
.modal-nav-button:last-of-type {
    right: 10px;
}

@media (max-width: 1200px) {
    .modal-content {
        width: 70vw; /* Ancho fijo del modal */
        height: 80vh; /* Altura fija del modal */
    }
}

/* Media queries para dispositivos móviles */
@media (max-width: 768px) {
    .modal-nav-button {
        font-size: 0.8rem;
    }
    .modal-content {
        width: 80%;
        height: 80%;
        margin: 0;
        border-radius: 0;
        padding: 1rem; /* Reduce el padding en pantallas pequeñas */
    }

    .modal-thumbnail {
        width: 60px;
        height: 45px;
    }

    .video-thumbnail {
        width: 60px;
        height: 45px;
    }
}

@media (max-width: 460px) {
    .modal-content {
        width: 95%;
        height: 65%;
        margin: 0;
        padding: 1rem; /* Reduce el padding en pantallas pequeñas */
    }
}