/* Contenedor del slider */
.novedades-slider-container {
    width: calc(100% - 5rem); 
    margin-left: 5rem;
    margin-right: auto;
    padding: 0;
    position: relative;
    animation: fadeInDownN 2s ease;
    margin-bottom: 1rem;
}

@keyframes fadeInDownN {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Contenedor del icono de carga */
.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
  
/* Icono de carga */
.loading-icon {
    width: 15rem;
    opacity: 0.5;
    height: auto;
}

/* Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
    .loading-icon {
      width: 28vw;
    }
}

@media (max-width: 480px) {
    .loading-icon {
        width: 30vw;
        max-width: 80px;
    }
}

/* Estilos de cada slide */
.novedad-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    border-radius: 10px;
    box-shadow: 3px 2px 10px #8ebf2f;
    height: auto;
    min-height: 80vh; /* Altura mínima para todas las tarjetas */
    padding: 0.3rem;
    background-color: white;
    justify-content: space-between; /* Distribuye espacio entre los elementos */
}

/* Contenedor de la imagen */
.novedad-image-container {
    width: 100%;
    height: 40vh; /* Ajusta la altura de la imagen */
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 1rem;
    flex-shrink: 0; /* Evita que el contenedor se reduzca */
}

.novedad-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Estilos del contenido */
.novedad-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centra verticalmente el contenido */
    flex-grow: 1; /* Asegura que el contenido ocupe todo el espacio disponible */
    padding-top: 1rem;
}

.novedad-content h2 {
    font-size: 1.7rem;
    margin-bottom: 1rem;
    text-align: center;
}

.novedad-description {
    padding: 0.1rem;
    font-size: 1rem;
    text-align: justify;
    margin-bottom: 1rem;
    line-height: 1.5;
    max-height: 20vh; /* Ajusta la altura máxima de la descripción */
    overflow: hidden;
    text-overflow: ellipsis; /* Trunca el texto si es demasiado largo */
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Muestra un máximo de 3 líneas */
    -webkit-box-orient: vertical;
    text-align: center;
}

.read-more-button {
    font-size: 1.2rem;
    background-color: #8fbf2e;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 0rem;
    align-self: center;
}

.read-more-button:hover {
    background-color: #75aa03;
}

/* Indicadores personalizados del carrusel */
.novedades-slider-container .mantine-Carousel-indicators .mantine-Carousel-indicator {
    width: 0.7rem;
    height: 0.7rem;
    background-color:  #008538;
    border-radius: 50%;
    border: none;
    margin: 0 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.novedades-slider-container .mantine-Carousel-indicators .mantine-Carousel-indicator[data-active='true'] {
    background-color:  #008538;
    transform: scale(1.5);
}

/* Botones de navegación del carrusel */
.novedades-slider-container .mantine-Carousel-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}

.novedades-slider-container .mantine-Carousel-control {
    background-color: #8fbf2e;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 4px 8px 8cbe22;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.novedades-slider-container .mantine-Carousel-control:hover {
    background-color: #89be20;
}

/* Media queries para mejor adaptación en dispositivos móviles */
@media(max-width: 920px) {
    .novedades-slider-container {
        margin-left: 0;
        padding: 1rem;
        width: 100%;
    }
    .novedades-slider-container {
        margin-bottom: 5rem;
    }
}

@media (max-width: 768px) {
    .novedades-slider-container {
        width: 100%;
        margin: 0;
    }
  
    .novedad-slide {
        margin: 0.5rem;
        padding: 0.5rem;
    }

    .novedad-image-container {
        height: 45vh; /* Ajusta la altura de la imagen en pantallas más pequeñas */
    }

    .novedad-content h2 {
        font-size: 1.2rem;
    }

    .read-more-button {
        font-size: 1rem;
    }
    .novedades-slider-container {
        margin-bottom: 10rem;
    }
}

@media (max-width: 480px) {
    .novedad-content h2 {
        font-size: 1rem;
    }
  
    .read-more-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}

/* Estilos del modal */
.modal-nove-overlay {
    backdrop-filter: blur(12px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(103, 143, 81, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-nove-content {
    animation: fadeInUp 1.5s ease;
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    position: relative;
    max-width: 90%; /* Controla el ancho máximo en pantallas grandes */
    max-height: 90%; /* Controla la altura máxima */
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center; /* Centra el contenido horizontalmente */
}

.modal-close-nove-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-nove-image {
    width: 100%; /* Ajusta el ancho del contenedor de la imagen */
    max-width: 600px; /* Establece un ancho máximo para el contenedor de la imagen */
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.modal-nove-image img {
    width: 100%; /* Ajusta la imagen para llenar el contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
    border-radius: 8px;
}

.modal-nove-text {
    text-align: center;
}

.modal-nove-text h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    line-height: 1.4;
}

.modal-nove-text p {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
}

/* Animación para el modal */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Ajuste en pantallas grandes */
@media (min-width: 1200px) {
    .modal-nove-content {
        max-width: 60%; /* Ajusta el ancho máximo en pantallas grandes */
    }
}