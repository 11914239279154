.desayuno-container {
    margin-left: 5rem; /* Espacio para la navbar */
    padding: 1rem; /* Espacio interno para que el contenido no toque los bordes */
    text-align: center;
    color: #333; /* Color de texto oscuro para buena legibilidad */
    margin-top:5vh;
    margin-bottom: 3rem;
    animation: fadeInUp 1s ease;
  }
  
  .desayuno-content {
    max-width: 70vw; /* Ancho máximo para el contenido */
    margin: 0 auto; /* Centra el contenido horizontalmente */
  }
  
  .desayuno-container h1 {
    border-top: 1px solid #008538;
    margin-top: 1rem;
    padding: 1rem;
    padding-top: 2rem;
    font-size: 2rem; /* Tamaño de fuente grande para el título */
    margin-bottom: 0; /* Espacio debajo del título */
    color: rgb(98, 99, 98); /* Color del título para mayor contraste */

    
  }
  .desayuno-container .desayuno-title{
    color: #008538;
    border: none;
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  .desayuno-container p {
    font-size: 1.45rem; /* Tamaño de fuente para la descripción */
    line-height: 1.6; /* Altura de línea para mejorar la legibilidad */
    margin-bottom: 1.75rem; /* Espacio debajo de la descripción */
    color: rgb(84, 85, 84);

  }
  
  @media (max-width: 1280px) {
    .desayuno-container {
      margin-top: 0;
      margin-left: 8rem;
      margin-right: 3rem;
    }
    .desayuno-content {
        max-width: 80vw; /* Ancho máximo para el contenido */
      }
  }
  @media (max-width: 920px) {
    .desayuno-container {
      margin-left: 2rem;
      margin-right: 2rem;
      margin-bottom: 4rem;
      margin-top: -2.5rem;
    }
    .desayuno-content {
        max-width: 100vw; /* Ancho máximo para el contenido */
        margin: 0 auto; /* Centra el contenido horizontalmente */
      }
  }
  
@media (max-width: 480px) {
.desayuno-content {
    max-width: 100vw;
    padding: 0;
    margin: 0;
    /* Ancho máximo para el contenido */
}
.desayuno-container h1 {
    font-size: 1.6rem;
}
.desayuno-container .desayuno-title{
    font-size: 2.2rem;
}
.desayuno-container p {
    font-size: 1.35rem; /* Tamaño de fuente para la descripción */
  }
}