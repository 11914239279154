@import url('./animations.css');

.header-container {
  margin-left: 5rem; /* Ajusta este margen según el ancho de tu navbar */
  margin-bottom: 0;
}

.title-with-logo {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Alinea los elementos a los extremos */
  flex-wrap: wrap; /* Permite envolver los elementos en pantallas más pequeñas */
  background-color:   #008538;
  border-radius: 10px;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 0.5rem;
  margin-top: 1rem;
  animation: fadeInDown 1.7s ease-out;
  box-shadow: 0 4px 8px rgb(98, 112, 99);
}

.logo-disp-container {
  display: none;
  text-align: center; /* Centra el contenido dentro del contenedor */
}

.logofund {
  width: 12rem;
  margin-bottom: 0;
  color: green;
  margin-right: 1rem;
  transition: 0.1s;
}

.logocloud {
  width: 5.5rem;
  margin-bottom: 0;
  margin-left: 1rem;
  transition: 0.1s;
}

.logofund:hover,
.logocloud:hover {
  transform: scale(1.06);
}

.header-title {
  font-size: 2rem;
  margin-bottom: 2vh;
  text-align: center; /* Centra el texto */
  color: white;
  margin-top: 2.5vh;
  font-weight: normal;
  cursor: default;
  animation: fadeInDown 1.3s ease-out;
}


@media (max-width: 1200px) {
  .header-title {
    font-size: 2.8vw;
  }
  .logocloud {
    width: 7vw;
  }
  .logofund {
    width: 14vw;
  }
}

@media (max-width: 920px) {
  .header-container {
    margin-left: 0;
    margin-bottom: 0;
  }

  .logofund {
    width: 15vw;
  }
  .logocloud {
    width: 7vw;
  }
  .header-title {
    font-size: 3.3vw;
  }

}

@media (max-width: 700px) {
  .header-title {
    font-size: 1.6rem; /* Tamaño de fuente más grande para pantalla pequeña */
    margin-top: 0; /* Ajusta el margen inferior */
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    text-align: center;
    margin: 0 auto; /* Centra el logotipo horizontalmente */
    transition: 0.15s;
  }
  .title-with-logo{
    margin-left: 1rem;
    margin-right: 1rem;
    transition: 0.2s;
  }
  .logocloud,
  .logofund {
    display: none;
  }

  .logo-disp-container {
    display: block;
    width: 100%;
    margin-top: 1rem;
  }

  .logo-disp {
    width: 11rem;
    margin: 0 auto; /* Centra el logotipo horizontalmente */
    animation: fadeInDown 1.3s ease-out;
    cursor: pointer;
    transition: 0.2s;
  }
  .logo-disp:hover{
    transform: scale(1.05);
  }
}

@media (max-width: 480px) {
  .header-container {
    margin-left: 0; 
    margin-bottom: 0.7rem;
  }
  .title-with-logo{
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1.5rem;
  }
  .header-title {
    font-size: 1.1rem;
    text-align: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

  }
  .logo-disp {
    width: 9rem;
  }
}