.list-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centramos horizontalmente */
  padding: 2rem;
  margin-left: 5rem; /* Espacio adicional para compensar la navbar */
  width: calc(100% - 5rem); /* Ajuste para el espacio extra en la izquierda */
  animation: fadeFromLeft 2s ease;
  transition: 0.5s;
  margin-bottom: 3rem;
}

/* Estilos para el contenedor de carga */
/* Contenedor del ícono de carga */
/* Contenedor del icono de carga */
.loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
}

/* Icono de carga */
.loading-icon {
  width: 15rem; /* Ancho relativo a la ventana */
  opacity: 0.5;
  height: auto; /* Mantiene la proporción de la imagen */
}

/* Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
  .loading-icon {
    width: 28vw; /* Aumenta el tamaño relativo para pantallas pequeñas */
  }
}

@media (max-width: 480px) {
  .loading-icon {
    width: 30vw; /* Aumenta el tamaño relativo para pantallas muy pequeñas */
    max-width: 80px; /* Ancho máximo aún más pequeño */
  }
}


/* Resto de tus estilos */
.list-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* Asegura que el ítem use todo el ancho disponible */
  max-width: 75rem; /* Limita el ancho máximo de los ítems */
  box-sizing: border-box; /* Incluye padding y border en el ancho total */
  min-height: 20vh;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  cursor: default;
}
.list-item:hover{
  transform: scale(1.02);
  box-shadow: 0px 0px 8px rgb(96, 201, 96);
}
.list-item img {
  width: 20vw;
  object-fit: cover;
  border-radius: 0;
  margin-right: 1rem;
}

.list-content {
  flex: 1;
  text-align: center;
  padding: 0;
}

.list-content h2 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  margin-top: 0 auto;
  color: rgb(98, 99, 98);
} 
.list-content p {
  font-size: 1.04rem;
  color: rgb(84, 85, 84);
}

@media (max-width: 1500px) {
  .list-content p {
    font-size: 1.3vw;
    color: #666;
  }
}

@media (max-width: 920px) {
  .list-container {
    margin-left: 0rem; /* Espacio adicional para compensar la navbar */
    align-items: right; /* Centramos horizontalmente */
    width: calc(100%); /* Ajuste para el espacio extra en la izquierda */
  }
}
@media (max-width: 880px) {
  .list-content h2 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .list-content p {
    font-size: 0.7rem;
    color: #666;
  }
  .list-item img {
    width: 15rem; /* Ajusta el ancho de la imagen */
  }
  .list-item {
    min-height: 1rem;
  }
}
/* Media Query para ajustar en dispositivos móviles */
@media (max-width: 700px) {
  .list-content h2 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }
  
  .list-content p {
    font-size: 0.9rem;
    color: #666;
  }
  .list-container {
    width: 100%; /* Ajusta el ancho al 100% */
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .list-item {
    flex-direction: column; /* Cambia a columna en dispositivos móviles */
  }

  .list-item img {
    width: 100%; /* Ajusta el ancho de la imagen */
    height: auto; /* Mantiene la relación de aspecto */
    margin-right: 0; /* Elimina el margen derecho */
    margin-bottom: 1rem; /* Agrega margen abajo para separación */
  }
}
@media (max-width: 480px) {
  .list-content h2 {
    font-size: 0.9rem; 
    margin-bottom: 0.5rem;
  }
  .list-container {
    width: 100%; /* Ajusta el ancho al 100% */
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .list-content p {
    font-size: 0.7rem;
    color: #666;
  }
  .list-item img {
    width: 100%; /* Ajusta el ancho de la imagen */
    margin-top: 0;
  }
  .list-item {
    min-height: 1rem;
  }
}
