/* animations.css */

@keyframes fadeOff {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeFromLeft {
    from {
        opacity: 0;
        transform: translateX(-35%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeFromRight {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes scaleText {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.07);
    }
  }
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes saltitos {
  0% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px); /* Ajusta la altura del salto */
  }
  100% {
      transform: translateY(0);
  }
}