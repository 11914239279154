@import url('./animations.css');

.loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001; /* Asegúrate de que el icono de carga esté encima del iframe */
  width: 100px;
  height: 100px;
}

.govtech-container {
  margin-left: 5rem; /* Ajusta este margen según el ancho de tu navbar */
  margin-bottom: 0;
}

.title-second {
  font-size: 4rem;
  text-align: center;
  color: #008538;
  margin-top: 17vh;
  animation: fadeFromLeft 1.3s ease-out; /* Aplica la animación */
}
.texto-gov{
  font-size: 2rem;
  text-align: center;
  color: #008538;
  margin-top: 1rem;
  animation: fadeFromLeft 2s ease-out; /* Aplica la animación */
  padding: 2rem
}

.img-grid-govtech {
  display: flex;
  justify-content: center; /* Centra las imágenes en la pantalla */
  flex-wrap: wrap; /* Permite que las imágenes se envuelvan en la siguiente línea si no caben en una fila */
  gap: 1rem; /* Espacio entre las imágenes */
  margin-top: 3vh;
  transition: 0.3s;
}

.imgs {
  width: 9.5rem; /* Ancho de las imágenes */
  cursor: pointer;
  transition: transform 0.4s; /* Transición suave para el efecto hover */
  border-radius: 25px;
  border-bottom: 10px solid #008538;
  border-right: 10px solid #008538;
  animation: fadeInUp 1.3s ease-out; /* Aplica la animación */
}

.imgs:hover {
  transform: scale(1.04); /* Efecto de agrandamiento al hacer hover */
  box-shadow: 0px 2px 18px #8fbf2e
}

.iframe-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 36, 35, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* que este arriba de otros elementos */
}

.iframe-overlay iframe {
  width: 80vw;
  height: 80vh;
  animation: fadeInUp 1s;
}

.close-button {
  position: absolute;
  color: white;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 4rem;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  animation: fadeOff 1s;
}

.close-button:hover {
  color: black;
  
}

@media (max-width: 1200px) {
  .title-second {
    margin-top: 17vh;
  }
  .texto-gov{
    font-size: 1.5rem;
    padding: 1rem
  }
}

@media (max-width: 920px) {
  .govtech-container {
    margin-left: 0;
    margin-bottom: 4rem;
  }
  .imgs {
    width: 10rem;
  }
  .img-grid-govtech {
    margin-top: 0;
  }
}

@media (max-width: 700px) {
  .imgs {
    width: 10rem;
  }
  .title-second {
    margin-top: 9vh;
  }
  .img-grid-govtech {
    margin-top: 1.5vh;
    margin-bottom: 5rem;
  }
}

@media (max-width: 480px) {
  .loading-icon {
    width: 1px;
    height: 1px;
  }
  .iframe-overlay iframe {
    width: 100vw;
    height: 70vh;
  }
  
  .close-button {
    background: none;
    font-size: 4rem;
    margin-top: 0;
    margin-right: 0.5rem;
  }
  
  .close-button:hover {
    color: white;
  }
  .govtech-container {
    margin-left: 0;
    margin-bottom: 4rem;
  }

  .imgs {
    width: 40vw;
  }

  .title-second {
    margin-top: 1vh;
    font-size: 2.4rem;
    border-radius: 20px;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .img-grid-govtech {
    margin-top: 1.5vh;
    margin-bottom: 10rem;
  }
}
