/* Estilos básicos para el navbar */
.navbarr {
    width: 5rem; 
    height: 100vh; 
    background-color: white;
    box-shadow: 0 4px 8px #008538;
    border-right: 1px solid black; 
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    z-index: 1000;
    animation: fadeFromLeft 1.5s;
}

.nav-link {
    color: white;
    text-decoration: none;
    text-align: center;
    width: 100%;
    margin: 4vh; 
    transition: background-color 0.3s, color 0.3s;
    display: flex; 
    align-items: center; 
    justify-content: center; 
}

.nav-link img {
    width: 2.9rem; 
    height: auto;
    transition: transform 0.2s;
}

.nav-link:hover img {
    transform: scale(1.3); 
}

@media (max-width: 920px) {
    .navbarr {
        width: 100%;
        height: 4rem;
        flex-direction: row;
        bottom: 0;
        top: auto;
        justify-content: space-around;
        border-right: none;
        background-color: white;
        border-top: 1px solid black;
        animation: fadeInUp 1.5s; /* Cambia la animación aca */
    }

    .nav-link {
        padding: 0;
        margin: 0; 
    }

    .app-container {
        flex-direction: column;
    }

    .main-content {
        margin-left: 0;
        margin-bottom: 4rem; 
    }

    .nav-link img {
        width: 2.65rem; 
        height: auto;
        transition: transform 0.2s;
    }
}

.main-content {
    margin-left: 4rem; 
}

.app-container {
    display: flex;
}

.app-container.vertical-nav {
    flex-direction: row;
}

.app-container.horizontal-nav {
    flex-direction: column;
}

.main-content {
    flex-grow: 1;
    padding: 1rem; 
}
